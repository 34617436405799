import * as React from "react";
import { FC } from "react";
import { ClaimEntryPoint } from "../../../entrypoints/claim";
import { LANG } from "../../../common/types/localization";
import { DefaultLayout } from "../../../components/layout/default";

export default (): FC => (
  <DefaultLayout lang={LANG.deCH} entrypoint={"claim"} page={"behauptung"}>
    <ClaimEntryPoint lang={LANG.deCH} />
  </DefaultLayout>
);
