import * as React from "react";
import { FC } from "react";
import { FancyLine } from "../common/components/lines/fancy-line";
import { Signature } from "../common/components/signature/signature";
import {
  Heading,
  HEADING_CLASS
} from "../common/components/typography/heading";
import { useLocalized } from "../common/localization/localize";
import { ILocalizedPage } from "../common/types/page";
import classes from "../components/claim/claim.module.css";

export const ClaimEntryPoint: FC<ILocalizedPage> = _props => {
  const title = useLocalized("claim_title");
  return (
    <>
      <Heading level={1} theme={HEADING_CLASS.MAIN}>
        {title}
      </Heading>
      <FancyLine />
      <Heading level={3} theme={HEADING_CLASS.TEXT}>
        {useLocalized("claim_teaser_1")}
      </Heading>
      <Heading level={3} theme={HEADING_CLASS.TEXT}>
        {useLocalized("claim_teaser_2")}
      </Heading>
      <div className={classes.imageBlock}>
        <img
          className={classes.image}
          src={"/claim/michelangelo.jpg"}
          alt={""}
        />
        <Heading level={3} theme={HEADING_CLASS.TEXT}>
          {useLocalized("claim_teaser_3")}
        </Heading>
      </div>
      <p>{useLocalized("claim_text_1")}</p>
      <p>{useLocalized("claim_text_2")}</p>
      <p>{useLocalized("claim_text_3")}</p>
      <p>{useLocalized("claim_text_4")}</p>
      <p>{useLocalized("claim_text_5")}</p>
      <p>{useLocalized("claim_text_6")}</p>
      <p>{useLocalized("claim_text_7")}</p>
      <div className={classes.imageBlock}>
        <img
          className={classes.image}
          src={"/claim/michelangelo1.jpg"}
          alt={""}
        />
        <div>
          <Heading level={3} theme={HEADING_CLASS.TEXT}>
            {useLocalized("claim_label_1")}
          </Heading>
          <div>{useLocalized("claim_label_2")}</div>
        </div>
      </div>
      <p>{useLocalized("claim_text_8")}</p>
      <p>{useLocalized("claim_text_9")}</p>
      <Signature />
    </>
  );
};
